/*------------------------------------
    Theme Preview
------------------------------------*/

.theme-preview {
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: inherit;
    overflow-x: hidden;
    position: relative;
}
.theme-preview-heading {
    text-align: center;
    margin-bottom: 90px;

    h2 {
        color: $black;
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 15px;
    }
    h3 {
        color: $black;
        font-size: 24px;
        font-weight: 700;
    }
}

.theme-preview-header {
    width: 100%;
    z-index: 999;
    position: absolute;

    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 84px;
        z-index: 1;
        opacity: 0.6;
        content: "";
        position: absolute;
        background: linear-gradient(180deg, $white 0%, transparent 100%);
    }
    .button {
        top: 25px;
        right: 30px;
        z-index: 3;
        position: absolute;
    }
    .header-logo {
        top: 30px;
        left: 30px;
        z-index: 99;
        position: fixed;
        a {
            color: $black;
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            border-bottom: 6px solid $primary;
        }
    }
}

.theme-preview-hero {
    position: relative;
    z-index: 66;
    background-color: #f6f5fa;

    .el-heading {
        z-index: 6;
        position: relative;
    }
    .button {
        margin-right: 3px;
        border: 1px solid $primary;

        .text {
            top: -2px;
            position: relative;
        }
        .wave {
            opacity: 0;
        }
        &:hover {
            .wave {
                opacity: 1;
            }
        }
        &.text-primary {
            color: $primary !important;
        }
        &.button-line-light {
            background-color: #fff;

            &:hover {
                color: $primary;
                background-color: #e1e1e1;
            }
        }
    }
    .el-heading {
        p,
        h1 {
            color: $black;
        }
    }
    .mockups {
        width: 420px;
        padding: 0;
        list-style: none;
        position: absolute;
        transform: rotate(30deg);

        li {
            width: 100%;
            display: block;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            transition: all 450ms;

            .holder {
                width: 100%;
                overflow: hidden;
                padding-top: 52%;

                img {
                    top: 0;
                    left: 0;
                    width: 100%;
                    position: absolute;
                }
            }

            &:nth-child(even) {
                .holder {
                    padding-top: 90%;
                }
            }

            &:hover {
                transform: scale(1.02, 1.02);
            }
        }

        &.mockups-1 {
            top: 120px;
            right: -50px;
        }
        &.mockups-2 {
            top: -150px;
            right: 300px;
        }
    }

    .el-shape {
        opacity: 0.3;

        &.el-shape-1 {
            top: 21%;
            left: 6%;
            position: absolute;
        }
        &.el-shape-2 {
            top: 45%;
            left: 3%;
            position: absolute;
            animation: animationFramesThree 30s alternate infinite linear;
        }
        &.el-shape-3 {
            top: 75%;
            left: 6%;
            opacity: 0.3 !important;
            position: absolute;
            animation: animationFramesOne 30s alternate infinite linear;
        }
    }
}

.theme-preview-demos {
    position: relative;
    background-color: $white;

    .item {
        text-align: center;
        transition: all 300ms;
        margin-bottom: 45px;

        h4 {
            color: $black;
            padding: 30px 0 0;
        }
        &:not(.no-hover):hover {
            transform: translate(0, -3px);

            h4 {
                color: $primary;
            }
        }
    }
}

.theme-preview-empty {
    height: 75vh;
    overflow: hidden;
    position: relative;

    .bg-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 75vh;
        position: absolute;
        animation: zoom 30s infinite;
        background-attachment: fixed;
    }
}

.theme-preview-ca {
    height: 500px;
    color: $base-1;
    position: relative;
    text-align: center;
    background: $primary fixed no-repeat center center / cover;

    h4 {
        color: $white;
        font-size: 50px;
        margin-bottom: 30px;

        span {
            border-bottom: 6px solid $primary;
        }
    }
    .bg-color {
        display: block;
    }
}

.theme-preview-features {
    background-color: $white;

    .item {
        text-align: center;
        position: relative;
        padding: 150px 20px 20px;
        margin-bottom: 30px;
        border: 1px solid $gray;
        box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
        transition: all 450ms;

        h4 {
            color: $black;
        }
        p {
            color: $black;
            opacity: 0.75;
            color: $base-1;
            font-size: 0.9em;
            font-weight: 400;
        }
        .image {
            top: 30px;
            left: 0;
            right: 0;
            width: 90px;
            height: 90px;
            position: absolute;
            margin: auto;
        }
        &:hover {
            transform: translate(0, -3px);
        }
    }
}

@media (max-width: 1200px) {
    .theme-preview-hero {
        .container {
            z-index: 99;
        }
        .mockups {
            opacity: 0.6;
            z-index: 1;
        }
    }
    .theme-preview-demos {
        z-index: 99;
    }
}
@media (max-width: 992px) {
    .theme-preview-ca {
        height: 400px;

        h4 {
            font-size: 24px;
        }
    }
    .theme-preview-hero {
        overflow: hidden;
        .mockups {
            opacity: 0.3;
            z-index: 1;
        }
        .el-heading {
            h1 {
                font-size: 45px;
            }
        }
    }
    .theme-preview-empty {
        display: none;
    }
    .theme-preview-header {
        .header-logo {
            top: 0;
            left: 0;

            a {
                top: 0;
                left: 0;
                background-color: #fff;
            }
        }
    }
}
@media (max-width: 992px) {
    .theme-preview-header {
        .button {
            display: none;
        }
    }
    .theme-preview-hero {
        .el-heading {
            .label {
                color: $black;
            }
        }
    }
}
@media (max-width: 768) {
    .theme-preview-hero {
        overflow: hidden;
        .mocups {
            opacity: 0.15;
            z-index: 1;
        }
        .el-heading {
            h1 {
                font-size: 39px !important;
            }
        }
    }
}
