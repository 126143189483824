/*------------------------------------
    Mixins
------------------------------------*/

// Clearfix
@mixin clearfix() {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

// Placeholder
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

// Border Radius
@mixin border-radius($value: 0) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    -ms-border-radius: $value;
    -o-border-radius: $value;
    border-radius: $value;
}

// Transition
@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

// Background Gradient
@mixin bg-gradient($deg: 0, $c1: $primary, $c2: $secondary) {
    background-image: linear-gradient($deg#{deg}, $c1 0%, $c2 100%);
}

// Overlay
@mixin overlay($type) {
    position: relative;
    @if ($type == "image") {
        background: no-repeat center center / cover;
    } @else if ($type == "color") {
        .bg-color {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    } @else if ($type == "gradient") {
        .bg-gradient {
            @extend .display-screen;
        }
    } @else if ($type == "pattern") {
        .bg-pattern {
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .bg-pattern-1 {
            position: absolute;
            background: repeat url("/assets/images/common/pattern-1.png") center center / 9px;
        }
        .bg-pattern-2 {
            position: absolute;
            background: repeat url("/assets/images/common/pattern-2.png") center center / 9px;
        }
        .bg-pattern-3 {
            position: absolute;
            background: repeat url("/assets/images/common/pattern-3.png") center center / 5px;
        }
        .bg-patline-1 {
            opacity: 0.6;
            position: absolute;
            background: repeat-x url("/assets/images/common/patline-1.png") top center;
        }
        .bg-pattern-a {
            opacity: .6;
            z-index: 1;
            position: absolute;
            animation: noise 60ms infinite;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCI+CjxmaWx0ZXIgaWQ9Im4iPgo8ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjciIG51bU9jdGF2ZXM9IjEwIiBzdGl0Y2hUaWxlcz0ic3RpdGNoIj48L2ZlVHVyYnVsZW5jZT4KPC9maWx0ZXI+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWxsPSIjMDAwIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9IjAuNCI+PC9yZWN0Pgo8L3N2Zz4=");
        }
    }
}

// Media
@mixin media-min($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}
@mixin media-max($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

// Keyframes
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin BoxShadow ($dp) {
    @if $dp==0 {
        box-shadow: none
    }
    @else if $dp==1 {
        box-shadow: 0 0 50px 0 rgba(64, 1, 4, 0.06);
    }
    @else if $dp==2 {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .20)
    }
    @else if $dp==3 {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12), 0 1px 8px 0 rgba(0, 0, 0, .20)
    }
    @else if $dp==4 {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .20)
    }
    @else if $dp==6 {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .20)
    }
    @else if $dp==8 {
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .20)
    }
    @else if $dp==9 {
        box-shadow: 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12), 0 5px 6px -3px rgba(0, 0, 0, .20)
    }
    @else if $dp==12 {
        box-shadow: 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12), 0 7px 8px -4px rgba(0, 0, 0, .20)
    }
    @else if $dp==16 {
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .20)
    }
    @else if $dp==24 {
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12), 0 11px 15px -7px rgba(0, 0, 0, .20)
    }
}

