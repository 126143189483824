/*------------------------------------
    Wrapper
------------------------------------*/

.route-wrapper {
    position: relative;

    > div {
        position: absolute;
    }
}
.switch-wrapper {
    height: 100%;
    position: relative;

    > div {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
